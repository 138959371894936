// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as localizedValues from "./wAFu0pn3D-0.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {E8b4y2f52: {hover: true}};

const cycleOrder = ["E8b4y2f52"];

const serializationHash = "framer-5ebh4"

const variantClassNames = {E8b4y2f52: "framer-v-9zpcms"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Zx6v6ZVYd: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, g3WdCDQUh: icon ?? props.g3WdCDQUh ?? "ShareNetwork", xtXylPKvy: link ?? props.xtXylPKvy} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, g3WdCDQUh, xtXylPKvy, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "E8b4y2f52", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={xtXylPKvy} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-9zpcms", className, classNames)} framer-1eikkdz`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"E8b4y2f52"} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"E8b4y2f52-hover": {backgroundColor: "var(--token-bae032fe-624b-466e-8683-a5dbab9eaa96, rgb(245, 245, 245))"}}} {...addPropertyOverrides({"E8b4y2f52-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1wec5v1-container"} layoutDependency={layoutDependency} layoutId={"ssVRteaRQ-container"}><Phosphor color={"var(--token-56f1cfa2-d50b-4ecc-94e2-9215ad4cd4a2, rgb(40, 119, 255))"} height={"100%"} iconSearch={getLocalizedValue("v0", activeLocale) ?? "House"} iconSelection={g3WdCDQUh} id={"ssVRteaRQ"} layoutId={"ssVRteaRQ"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5ebh4.framer-1eikkdz, .framer-5ebh4 .framer-1eikkdz { display: block; }", ".framer-5ebh4.framer-9zpcms { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 8px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-5ebh4 .framer-1wec5v1-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5ebh4.framer-9zpcms { gap: 0px; } .framer-5ebh4.framer-9zpcms > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5ebh4.framer-9zpcms > :first-child { margin-left: 0px; } .framer-5ebh4.framer-9zpcms > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"PW8ODgZx0":{"layout":["auto","auto"]}}}
 * @framerVariables {"g3WdCDQUh":"icon","xtXylPKvy":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerwAFu0pn3D: React.ComponentType<Props> = withCSS(Component, css, "framer-5ebh4") as typeof Component;
export default FramerwAFu0pn3D;

FramerwAFu0pn3D.displayName = "ShareIcon";

FramerwAFu0pn3D.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerwAFu0pn3D, {g3WdCDQUh: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "ShareNetwork", description: undefined, hidden: undefined, title: "Icon"}, xtXylPKvy: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerwAFu0pn3D, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})